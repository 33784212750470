<template>
    <b-container>
      <!-- <h1 class="mb-3">{{ $t('Material de Apoio') }}</h1> -->
      <b-row>
        <b-col v-for="pdf in pdfs" :key="pdf.id" cols="12" md="6" lg="4">
          <b-card>
            <h5>{{ $t(pdf.title) }}</h5>
            <b-card-text>{{ $t(pdf.description) }}</b-card-text>
            <b-button variant="primary" @click="downloadPdf(pdf.src)">{{ $t('Download PDF') }}</b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        pdfs: [
          {
            id: 1,
            title: 'Apresentação do Negócio em Inglês',
            description: 'Este documento apresenta os principais detalhes do negócio New X Coins em inglês.',
            src: ('/marketing/presentation/apn_en.pdf')
          },
          {
            id: 2,
            title: 'Apresentação do Negócio em Francês',
            description: 'Este documento apresenta os principais detalhes do negócio New X Coins em francês.',
            src: ('/marketing/presentation/apn_fr.pdf')
          },
          {
            id: 3,
            title: 'Apresentação do Negócio em Português',
            description: 'Este documento apresenta os principais detalhes do negócio New X Coins em português.',
            src: ('/marketing/presentation/apn_pt-br.pdf')
          },
          {
            id: 4,
            title: 'Apresentação do Negócio em Polonês',
            description: 'Este documento apresenta os principais detalhes do negócio New X Coins em polonês.',
            src: ('/marketing/presentation/apn_pl.pdf')
          },
          {
            id: 5,
            title: 'Apresentação do Negócio em Romeno',
            description: 'Este documento apresenta os principais detalhes do negócio New X Coins em romeno.',
            src: ('/marketing/presentation/apn_ro.pdf')
          },
          {
            id: 6,
            title: 'Apresentação do Negócio em Russo',
            description: 'Este documento apresenta os principais detalhes do negócio New X Coins em russo.',
            src: ('/marketing/presentation/apn_ru.pdf')
          },
          {
            id: 7,
            title: 'Apresentação do Negócio em Vietnamita',
            description: 'Este documento apresenta os principais detalhes do negócio New X Coins em vietnamita.',
            src: ('/marketing/presentation/apn_vi.pdf')
          },          
        ]
      };
    },
    methods: {
      downloadPdf(src) {
        const link = document.createElement('a');
        link.href = src;
        link.download = src.split('/').pop(); // Nome do arquivo baseado no URL do PDF
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  </script>
  
  <style scoped>
  .my-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  </style>
  